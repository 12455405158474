import { RouteRecordRaw } from "vue-router";

const routesEvidenca: RouteRecordRaw[] = [
  {
    path: "/",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/IndexEvidenca.vue")
      },
      {
        path: "/calendar",
        component: () => import("pages/Calendar.vue")
      },
      {
        path: "/statistics",
        component: () => import("pages/Statistics.vue")
      },
    ],
    meta: {
      requireAuth: true,
      permission: null
    }
  },
  {
    path: "/topics",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/topics/index.vue")
      },
      {
        path: "/topics/create",
        component: () => import("pages/topics/view.vue"),
        meta: {
          permission: {
            action: "create",
            model: "topics"
          }
        }
      },
      {
        path: "/topics/suggest",
        component: () => import("pages/topics/suggest.vue"),
        meta: {
          permission: {
            action: "suggest",
            model: "topics"
          }
        }
      },
      {
        path: "/topics/:id",
        component: () => import("pages/topics/view.vue"),
        props: true,
        meta: {
          permission: {
            action: "view",
            model: "topics"
          }
        }
      },
      {
        path: "/topics/:id/duplicate",
        component: () => import("pages/topics/view.vue"),
        props: { duplicate: true },
        meta: {
          permission: {
            action: "create",
            model: "topics"
          }
        }
      },
    ],
    meta: {
      requireAuth: true,
      permission: {
        action: "view",
        model: "topics"
      }
    }
  },
  {
    path: "/courses",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/courses/index.vue")
      },
      {
        path: "/courses/create",
        component: () => import("pages/courses/view.vue"),
        meta: {
          permission: {
            action: "create",
            model: "courses"
          }
        }
      },
      {
        path: "/courses/:id",
        component: () => import("pages/courses/view.vue"),
        props: true,
        meta: {
          permission: {
            action: "view",
            model: "courses"
          }
        }
      },
      {
        path: "/courses/:id/duplicate",
        component: () => import("pages/courses/view.vue"),
        props: { duplicate: true },
        meta: {
          permission: {
            action: "create",
            model: "courses"
          }
        }
      }
    ],
    meta: {
      requireAuth: true,
      permission: {
        action: "view",
        model: "courses"
      }
    }
  },
  {
    path: "/schools",
    component: () => import("layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        component: () => import("pages/schools/index.vue")
      },
      {
        path: "/schools/create",
        component: () => import("pages/schools/view.vue"),
        meta: {
          permission: {
            action: "create",
            model: "schools"
          }
        }
      },
      {
        path: "/schools/:id",
        component: () => import("pages/schools/view.vue"),
        props: true,
        meta: {
          permission: {
            action: "view",
            model: "schools"
          }
        }
      },
      {
        path: "/schools/:id/duplicate",
        component: () => import("pages/schools/view.vue"),
        props: { duplicate: true },
        meta: {
          permission: {
            action: "create",
            model: "schools"
          }
        }
      }
    ],
    meta: {
      requireAuth: true,
      permission: {
        action: "view",
        model: "schools"
      }
    }
  },
  {
    path: "/register",
    name: "register",
    component: () => import("layouts/EmptyLayout.vue"),
    children: [{
      path: "",
      component: () => import("pages/Register.vue")
    }],
  },
  {
    path: "/email-verify",
    name: "email-verify",
    component: () => import("layouts/EmptyLayout.vue"),
    children: [{
      path: "",
      component: () => import("pages/VerifyEmail.vue")
    }],
  },
];

export default routesEvidenca;
